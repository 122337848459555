import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gis from "/src/components/img/gis"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Legenda mapy" />
    <h1>Legenda mapy</h1>

    <p><strong>Legenda mapy</strong>&nbsp;je seznam mapov&yacute;ch značek, př&iacute;padně dal&scaron;&iacute; vysvětlivky slouž&iacute;c&iacute; k použ&iacute;v&aacute;n&iacute; mapy. Jde o&nbsp;<Link to="/gis/mapova-kompozice/">z&aacute;kladn&iacute; kompozičn&iacute; prvek</Link>, kter&yacute; by až na v&yacute;jimky neměl v mapov&eacute; kompozici chybět, a jej&iacute; vypracov&aacute;n&iacute; patř&iacute; mezi nejz&aacute;važněj&scaron;&iacute; a nejobt&iacute;žněj&scaron;&iacute; &uacute;koly při tvorbě mapy.</p>
    <p>V&yacute;jimku tvoř&iacute; forma legendy jakožto vysvětlivek v samostatn&eacute; knižn&iacute; př&iacute;loze u velmi komplikovan&yacute;ch děl (např. geologick&eacute; mapy), v jednotliv&yacute;ch listech se pak legenda nevyskytuje a množstv&iacute; znaků je v nich mnohem vy&scaron;&scaron;&iacute;.</p>
    <p>Dal&scaron;&iacute;m speci&aacute;ln&iacute;m př&iacute;padem jsou mapov&eacute; aplikace, kde může m&iacute;t interaktivn&iacute; podobu.</p>
    <hr /><h2>Proces kartografick&eacute;ho k&oacute;dov&aacute;n&iacute;</h2>
    <p>Proces <strong>sestaven&iacute; legendy</strong> lze t&eacute;ž pojmenovat jako<strong> proces kartografick&eacute;ho k&oacute;dov&aacute;n&iacute;</strong>.&nbsp;Z&aacute;kladem pro zpracov&aacute;n&iacute; legendy je obsah konkr&eacute;tn&iacute; mapy, tř&iacute;děn&iacute; použit&yacute;ch kartografick&yacute;ch znaků do skupin a hierarchizace těchto znaků v r&aacute;mci skupin. Při tom je třeba br&aacute;t ohled na v&yacute;znam zn&aacute;zorňovan&yacute;ch objektů či jevů a z&aacute;sady izomorfismu.</p>
    <hr /><h2>Dělen&iacute; legendy</h2>
    <p>Legendu lze sestavit dle charakteru obsahu mapy, pak může b&yacute;t legenda:</p>
    <ul>
    <li><strong>jednoduch&aacute;</strong>&nbsp;(tj. prvkov&aacute;, element&aacute;rn&iacute;; obvykle na analytick&yacute;ch map&aacute;ch)<strong>,</strong></li>
    <li><strong>kombinovan&aacute;</strong>&nbsp;(obvykle na komplexn&iacute;ch map&aacute;ch)<strong>,</strong></li>
    <li><strong>složen&aacute; kombinovan&aacute;</strong>&nbsp;(tj. tabulkov&aacute;, klasifikačn&iacute; sch&eacute;mata; obvykle na syntetick&yacute;ch map&aacute;ch)<strong>.</strong></li>
    </ul>
    <p>Podle typu mapy pak může b&yacute;t legenda:</p>
    <ul>
    <li><strong>typologick&aacute;</strong>&nbsp;(mapy s rozpracovanou typologi&iacute; či klasifikac&iacute; prvků obsahu),</li>
    <li><strong>region&aacute;ln&iacute;</strong>&nbsp;(mapy mozaikov&eacute;, zachycuj&iacute;c&iacute; individu&aacute;ln&iacute; &uacute;zem&iacute;),</li>
    <li><strong>chronologick&aacute;</strong>&nbsp;(mapy zn&aacute;zorňuj&iacute;c&iacute; genezi, stratigrafii či časovou dynamiku jevů).</li>
    </ul>
    <hr /><h2>Kartografick&eacute; z&aacute;sady tvorby legendy</h2>
    <p>Pro to, aby plnila legenda svou funkci co nejefektivněji, je až na v&yacute;jimky nutn&eacute; dodržovat z&aacute;kladn&iacute; kartografick&eacute; z&aacute;sady, mezi něž patř&iacute;:</p>
    <ul>
    <li><strong>&uacute;plnost legendy,</strong></li>
    <li><strong>mus&iacute; b&yacute;t v souladu s označov&aacute;n&iacute;m na mapě,</strong></li>
    <li><strong>srozumitelnost, čitelnost a dobr&aacute; zapamatovatelnost,</strong></li>
    <li><strong>mus&iacute; b&yacute;t sestavena v logicky uspoř&aacute;dan&yacute; syst&eacute;m,</strong></li>
    <li><strong>nez&aacute;vislost legendy.</strong></li>
    </ul>
    <h3>&Uacute;plnost legendy</h3>
    <p><strong>&Uacute;plnost legendy</strong>&nbsp;lze ch&aacute;pat tak, že jsou v n&iacute; obsaženy v&scaron;echny prvky nutn&eacute; k dek&oacute;dov&aacute;n&iacute; informace. To znamen&aacute;, že v legendě by měly b&yacute;t v&scaron;echny znaky použit&eacute; v mapě. Do v&yacute;jimek &uacute;plnosti legendy patř&iacute; např&iacute;klad tematick&eacute; mapy, kdy prvky topografick&eacute;ho podkladu mohou, ale nemus&iacute; b&yacute;t zahrnuty v legendě. Rovněž se &uacute;plnost legendy nevztahuje na matematick&eacute;, doplňkov&eacute; a pomocn&eacute; prvky obsahu mapy.</p>
    <h3>Soulad s označen&iacute;m na mapě</h3>
    <p>Legenda&nbsp;<strong>mus&iacute; b&yacute;t v souladu s označov&aacute;n&iacute;m na mapě</strong>, tj. mus&iacute; označovat stejn&eacute; stejn&yacute;m. Je častou chybou, že znaky v legendě a na mapě nejsou provedeny stejně, nejčastěj&scaron;&iacute; chybou je pak rozd&iacute;ln&aacute; velikost znaků. Ta je n&aacute;sledovan&aacute; &scaron;patnou barvou či tvarem znaku.</p>
    <h3>Srozumitelnost, čitelnost a dobr&aacute; zapamatovatelnost</h3>
    <p>Takt&eacute;ž mus&iacute; b&yacute;t&nbsp;<strong>srozumiteln&aacute;, čiteln&aacute; a dobře zapamatovateln&aacute;</strong>. Mus&iacute; b&yacute;t vypracov&aacute;na dle z&aacute;sad jazyka mapy s ohledem na okruh budouc&iacute;ch uživatelů.</p>
    <h3>Legenda jako logicky uspoř&aacute;dan&yacute; syst&eacute;m</h3>
    <p><strong>Logicky uspoř&aacute;dan&yacute; syst&eacute;m legendy</strong>&nbsp;je pro dobrou orientaci v n&iacute; nezbytn&yacute;. Pokud je to možn&eacute;, tento syst&eacute;m by měl odpov&iacute;dat re&aacute;ln&eacute; struktuře zobrazen&eacute;ho jevu. Rovněž bychom měli v r&aacute;mci skupin zachov&aacute;vat posloupnost znaků (nejdůležitěj&scaron;&iacute; nahoru, nejm&eacute;ně důležit&yacute; dolů). Př&iacute;kladem může b&yacute;t seskupen&iacute; znaků představuj&iacute;c&iacute; s&iacute;dla do jedn&eacute; skupiny a seskupen&iacute; znaků reprezentuj&iacute;c&iacute;ch silnice do druh&eacute;. V př&iacute;padě s&iacute;del pak budou znaky seřazeny kupř&iacute;kladu dle počtu obyvatel, silnice pak dle tř&iacute;d (d&aacute;lnice nejv&yacute;&scaron;e, silnice III. tř&iacute;dy nejn&iacute;že).</p>
    <h3>Nez&aacute;vislost legendy</h3>
    <p><strong>Nez&aacute;vislost&iacute; legendy</strong>&nbsp;se rozum&iacute; zamezen&iacute; duplicitn&iacute; kartografick&eacute; reprezentace jednoho jevu. Jeden objekt či jev mus&iacute; b&yacute;t jednoznačně přiřaditeln&yacute; k jednomu znaku a naopak. Př&iacute;kladem poru&scaron;en&iacute; nez&aacute;vislosti legendy je v&yacute;skyt kategorie &bdquo;kostel&ldquo; a &bdquo;c&iacute;rkevn&iacute; objekt&ldquo; na jedn&eacute; mapě. Kostel lze pak přiřadit pod znak reprezentuj&iacute;c&iacute; kostel i c&iacute;rkevn&iacute; objekt, což je matouc&iacute;.</p>
    <hr /><h2>Dal&scaron;&iacute; z&aacute;sady při sestavov&aacute;n&iacute; legendy</h2>
    <p>Pro sestavov&aacute;n&iacute; legendy existuje mnoho dal&scaron;&iacute;ch z&aacute;sad a rad. Mezi ně patř&iacute; např&iacute;klad poučka o&nbsp;<strong>optim&aacute;ln&iacute;m počtu znaků v legendě</strong>. Podle profesora &Scaron;im&aacute;ka je tento počet použit&yacute; v legendě (tzn. i mapě) nejv&yacute;&scaron;e 25 až 30. Podle Ratajskiho by pak celkov&yacute; počet znaků v jednotliv&yacute;ch skupin&aacute;ch neměl překročit č&iacute;slo 7.</p>
    <p>Legenda by měla b&yacute;t d&aacute;le&nbsp;<strong>um&iacute;stěna bl&iacute;zko mapov&eacute;ho kresby</strong>&nbsp;a<strong>&nbsp;nepouž&iacute;v&aacute; se pro ni nadpis &bdquo;Legenda&ldquo;</strong>. Pokud v n&iacute; znak popisuje jeden jev (např&iacute;klad les), popisuje se v jednotn&eacute;m č&iacute;sle. Nicm&eacute;ně, pokud souhrnně vyjadřuje v&iacute;ce jevů či objektů (např&iacute;klad chaty are&aacute;lov&yacute;m znakem), použ&iacute;v&aacute; se popis v množn&eacute;m č&iacute;sle.</p>
    <p>V hierarchicky uspoř&aacute;dan&eacute; legendě je vhodn&eacute;&nbsp;<strong>každou &uacute;roveň popsat jin&yacute;m p&iacute;smem</strong>, a to např&iacute;klad pomoc&iacute; odli&scaron;n&eacute; velikosti fontu, tlou&scaron;ťkou p&iacute;sma či verz&aacute;lek. Alespoň prvn&iacute; a druh&aacute; &uacute;roveň by měla b&yacute;t odsazen&aacute;. D&aacute;le, ikdyž se v legendě vyskytuje pouze jedna kategorie ve skupině, mus&iacute; b&yacute;t jako kategorie pops&aacute;na.</p>

    <hr />
    <ImgCon><Gis /><div>
    <h2>Studijn&iacute; materi&aacute;ly GIS, geoinformatika a&nbsp;kartografie</h2>
    <p>T&eacute;ma <strong>Legenda mapy</strong> je souč&aacute;st&iacute; <Link to="/gis/">studijn&iacute;ch materi&aacute;lů GIS, geoinformatika a kartografie</Link> vytvořených zejména na podkladě předmětů vyučovaných na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat, případně na téma předch&aacute;zej&iacute;c&iacute; či n&aacute;sleduj&iacute;c&iacute;.</p>
    <BtnWrap>
    <Link to="/gis/"><button>Rozcestník GIS</button></Link>
    <Link to="/gis/mapova-kompozice/meritko/"><button className="inv">&larr; Měřítko na mapě</button></Link>
    <Link to="/gis/mapova-kompozice/nazev-mapy/"><button className="inv">Název mapy &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
